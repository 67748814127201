body { margin: 0; font-family: 'Open Sans', sans-serif; color: #FF6666;}

.card-container {
  max-width: 50vw;
  margin-left: auto;
  margin-right: auto;
}

input {
  width: 200px;
  border: 1px solid gray;
  border-radius: 3px;
  height: 30px;
  padding: 10px;
  margin-bottom: 10px;
  margin: 10px;
  font-size: 16px;
}

input:focus {
  font-size: 16px;
}

::placeholder {
  font-size: 1rem;
}

button, .button {
  background-color: #FF6666;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 500;

}

button:hover, .button:hover {
  cursor: pointer;
  background-color: #fff;
  color: #FF6666;
  border: 2px solid #FF6666;
  font-weight: 500;
}

/* CSS HEX */
/* --bittersweet: #FE6765ff; */
/* --citrine: #E6D833ff; */
/* --dark-sea-green: #84B67Cff; */
/* --russian-green: #69975Fff; */
/* --fern-green: #497741ff; */
/* --black: #010101ff; */

@media (max-width: 400px) {
  button {
      width: 170px;
      margin: 5px;
      height: 120px;
      /* margin-bottom: 40px; */
  }
}
